<template>
  <div class="postBeacon" id="beaconMap">
    <input type="file" name="file" id="fileId" multiple>
    <button @click="clickButton">提交</button>
  </div>
</template>

<script>


import dataInfo from '@/views/postBeacon/svg/apJS'

import { createModule } from '@/assets/JS/moduleJs'

let count = 0

export default {
  name: 'postBeacon',
  data() {
    return {

      // tenantId: '1ec11750d06fb90bbbff7c5ed841416',
      // buildingId: 10,

      tenantId: '1ec1144ecef3230b7fb59704b983120',
      buildingId: 1,
      loadMap: false,
      module: createModule(),
      mapList: []
    }
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      const div = document.getElementById('beaconMap')
      this.module.createMap('beaconMap', div.offsetWidth, div.offsetHeight, 0) // 先初始化地图参数
      this.getMapDetail().then(res => {
        this.updateMapDataInfo(res)
      })
    })
  },
  beforeDestroy() {
    this.module.realseMap()
  },
  methods: {
    updateMapDataInfo: function(res) {
      if (res.result == false) {
        return
      }
      this.showMap(res.mapList, () => {
        this.loadMap = true
        console.log('loadMapFinish')
      })
    },
    getMapDetail: function() {
      return new Promise(resolve => {
        const fields = 'mapid,data,scale,zoneInfo,name,wall'
        this.$api.userInfo
            .getOrgMapDetail({ buildingId: this.buildingId, deleted: 0, fields: fields, tenantId: this.tenantId })
            .then(res => {
              if (res.code != 200) {
                resolve({ result: false })
                return
              }
              const mapList = []
              let dataList = []
              if (typeof res.data.map != 'undefined' && res.data.map != null) {
                dataList = res.data.map
              }
              for (let i = 0; i < dataList.length; i++) {
                const info = dataList[i]
                info['order'] = dataList.length - i
                mapList.push(info)
              }
              resolve({ result: true, mapList: mapList })
            })
            .catch(() => {
              resolve({ result: false })
            })
      })
    },
    getDeviceList: function() {
      let updateTenantId = '1ec25a97b8a88a2b959431d326b8315'
      let getTenantId = '1ec145b17d29960b7fb59704b983120'

      if (getTenantId == '' || updateTenantId == '') {
        return;
      }

      let startUpdate = (list, index, callBack) => {
        if (index >= list.length) {
          callBack()
          console.log('end')
          return
        }
        let dataInfo = JSON.parse(JSON.stringify(list[index]));
        console.log(index)
        console.log(dataInfo)
        dataInfo['tenantId'] = updateTenantId;

        this.$api.assetModule.updateDeviceList(dataInfo).then(res => {
          setTimeout(()=>{
            startUpdate(list, index + 1, callBack);
          }, 100);
        })
      }

      let getPost = { deleted: 0, query: '', page: 1, pageSize:1000, order:'',orderType:'', tenantId:getTenantId, isTenantIdChild:1, categoryId:''}
      this.$api.assetModule.getDeviceList(getPost).then(res => {
        if (res.code != 200) {
          console.log('error')
          return
        }

      })
    },
    updateDevice: function() {

    },
    clickButton: function() {
      if (this.loadMap == false) {
        alert('地图数据尚未加载完成')
        return
      }
      let objFile = document.getElementById('fileId')
      if (objFile.value == '') {
        alert('文件不能空')
        return
      }
      let fileDiv = document.getElementById('fileId')

      let list = fileDiv.files
      if (list.length == 0) {
        alert('文件不能空 - list.length == 0')
        return
      }
      this.startLoadSvg(0, list)
    },
    startLoadSvg: function(index, list) {
      if (index >= list.length) {
        console.log('完成!')
        return
      }
      let reader = new FileReader()//新建一个FileReader
      let file = list[0]
      let name = file.name
      reader.readAsText(file, 'UTF-8')//读取文件
      reader.onload = (evt) => {
        let fileString = evt.target.result // 读取文件内容
        this.readSvg(fileString, name.replace(/.svg/g, '')).then(res => {
          this.startLoadSvg(index + 1, list)
        })
      }
    },
    showMap: function(mapInfos, callBack) {
      this.module.updateMapDataInfo(mapInfos, {}, list => {
        this.mapList = list
        callBack()
      })
    },
    readSvg: function(fileString, name) {

      let readChildNodes = (index, childNodes, mapId, callBack) => {
        if (index >= childNodes.length) {
          callBack()
          return
        }
        let item = childNodes[index]
        if (item.nodeName != 'rect') {
          readChildNodes(index + 1, childNodes, mapId, callBack)
          return
        }
        let id = item.getAttribute('id')
        let x = parseFloat(item.getAttribute('x'))
        let z = parseFloat(item.getAttribute('y'))
        let width = parseFloat(item.getAttribute('width'))
        let height = parseFloat(item.getAttribute('height'))
        let info = {
          mapId: mapId,
          name: id,
          x: x - width / 2.0,
          y: 0,
          z: z - height / 2.0
        }
        this.module.poistionTest(info)
        this.sendPost(info, () => {
          setTimeout(() => {
            readChildNodes(index + 1, childNodes, mapId, callBack)
          }, 10)
        })
      }

      let readSvgNote = (index, svgNote, mapId, callBack) => {
        if (index >= svgNote.length) {
          callBack()
          return
        }
        let itemNote = svgNote[index]
        let childNodes = itemNote['childNodes']
        readChildNodes(0, childNodes, mapId, () => {
          readSvgNote(index + 1, svgNote, mapId, callBack)
        })
      }

      return new Promise(resolve => {
        let mapId = ''
        let mapList = this.mapList
        for (let i = 0; i < mapList.length; i++) {
          let mapInfo = mapList[i]
          if (mapInfo.name == name) {
            mapId = mapInfo.mapid
            break
          }
        }
        if (mapId == '') {
          resolve()
          return
        }
        let parser = new DOMParser()
        let xmlDoc = parser.parseFromString(fileString, 'text/xml')
        let svgNote = xmlDoc.getElementsByTagName('svg')
        readSvgNote(0, svgNote, mapId, () => {
          resolve()
        })
      })
    },
    sendPost: function(data, callBack) {
      let updateBeacon = (data) => {
        return new Promise(resolve => {
          count = count + 1
          this.$api.assetModule.addDevice(data).then(res => {
            console.log(count)
            if (res.code != 200) {
              console.log('error')
              console.log(data)
              resolve({
                result: false
              })
              return
            }
            resolve({
              result: true
            })
          }).catch(res => {
            console.log('update error')
            console.log(data)
            console.log(res)
            resolve({
              result: false
            })
            return
          })
        })
      }

      let checkBeacon = (data) => {
        return new Promise(resolve => {
          if (typeof data['macAddr'] == 'undefined') {
            resolve({
              result: false
            })
            return
          }
          this.$api.assetModule
              .getList({
                typeId: 'X1',
                query: data['macAddr'],
                page: 1,
                pageSize: 20
              }).then(res => {
            if (res.code != 200) {
              resolve({
                result: false
              })
              return
            }
            if (res.data.length > 1) {
              console.log('存在多解')
              console.log(data)
              resolve({
                result: false
              })
              return
            }
            if (res.data.length == 1) {
              let info = res.data[0]
              data['id'] = info['id']
            }
            resolve({
              result: true
            })
          }).catch(res => {
            console.log(data)
            console.log(res)
            resolve({
              result: false
            })
            return
          })
        })
      }

      let names = data.name.split('-')
      let name = names[names.length - 1]
      for (let i = 0; i < dataInfo.length; i++) {
        let ap = dataInfo[i]
        let apNames = ap.name.split('-')
        if (name != apNames[apNames.length - 1]) {
          continue
        }
        if (typeof ap['mac'] == 'undefined' || ap['mac'] == '') {
          callBack()
          return
        }
        data['macAddr'] = ap['mac']
        data['tenantId'] = this.tenantId
        data['typeId'] = 'X1'
        data['label'] = data['name']
        data['place'] = (data.zoneName ? data.zoneName + ' ' : '') + (data.mapName ? data.mapName + ' ' : '') + data.roomName
        data['areaId'] = data['areaid']
        data['fixedPosition'] = 0
        data['enable'] = 1
        data['greenChannelSensor'] = 0
        break
      }

      checkBeacon(data).then(res => {
        if (res.result == false) {
          callBack()
          return
        }

        updateBeacon(data).then(res => {
          callBack()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.postBeacon {
  width: 100%;
  height: 100%;
  position: absolute;
}

</style>
